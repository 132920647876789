// Journey link styling
.journey-link {
  @extend .box;
  @extend .is-relative;
  @extend .px-3;
  @extend .py-2;

  &.is-active {
    background: $primary-light;
  }

  // Link spacing
  &:not(:last-child) {
    margin-bottom: $media-content-spacing;
  }
}

// Journey box styling
.journey-box {
  @extend .box;
  @extend .mb-0;

  // Header box styling
  &.is-header {
    @extend .has-background-primary;
  }

  // Route box styling
  &.is-route {
    @extend .has-background-white;
    @extend .p-2;

    // Border for consecutive route boxes
    & + & {
      border-top: 0.25rem solid $grey-lighter;
    }
  }

  // Transfer box styling
  &.is-transfer {
    @extend .has-background-grey-lighter;
    @extend .has-text-grey;
    @extend .px-2;
    @extend .py-3;
  }

  // Negating border radius
  @include mobile {
    border-radius: 0 !important;
  }
  @include tablet {
    &:not(:first-child) {
      border-top-left-radius: 0 !important;
      border-top-right-radius: 0 !important;
    }
    &:not(:last-child) {
      border-bottom-left-radius: 0 !important;
      border-bottom-right-radius: 0 !important;
    }
  }
}

// Journey row styling
.journey-row {
  @extend .is-flex;
  @extend .is-flex-direction-row;

  max-width: 100%;

  // Bold journey row styling
  &.is-bold {
    @extend .has-text-weight-bold;
  }

  // Journey cell styling
  .journey-cell {
    @extend .p-1;

    // Paragraph styling
    p {
      @extend .mb-0;
    }

    // Time cell
    &.is-time {
      @extend .is-flex-shrink-0;
      @extend .is-flex-grow-0;
      @extend .has-text-right;

      width: 3.5rem;
    }

    // Line cell
    &.is-line {
      @extend .is-flex-shrink-0;
      @extend .is-flex-grow-0;
      @extend .has-text-centered;

      position: relative;
      line-height: 0px;
      width: 3rem;

      // Line segment
      & > .line {
        @extend .is-block;

        position: absolute;
        left: calc(50% - 2px);
        top: 0;

        margin-top: -4px;
        margin-bottom: -4px;
        height: calc(100% + 8px);

        border-left: 2px solid $primary;
        border-right: 2px solid $primary;

        &.is-start {
          height: calc(100% - 16px);
          top: 24px;
          bottom: 0;
        }

        &.is-end {
          height: 16px;
          top: 0;
          bottom: calc(100% - 24px);
        }
      }

      & > .icon * {
        z-index: 1;
      }
    }

    // Node cell
    &.is-node {
      @extend .is-flex-shrink-1;
      @extend .is-flex-grow-1;
    }

    // Platform cell
    &.is-platform {
      @extend .is-flex-shrink-0;
      @extend .is-flex-grow-0;
      @extend .has-text-weight-bold;

      width: 4.5rem;
    }
  }

  // Cancelled styling
  span.is-cancelled a {
    @extend .has-text-grey;
  }

  // Additional label styling
  span.is-additional-label {
    @extend .has-text-info-dark;
    @extend .has-text-weight-bold;
  }

  // Cancelled label styling
  span.is-cancelled-label {
    @extend .has-text-danger-dark;
    @extend .has-text-weight-bold;
  }
}
