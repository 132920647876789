@use 'sass:list';


// Import custom variables
@import 'variables';

// Import the frameworks
@import 'bulma';

// Import modules
@import 'layout';
@import 'text';
@import 'content';
@import 'journey';


// Collapse styling
.collapse {
  display: none;
}

// Dropdown styling
.dropdown {
  &.is-fullwidth {
    @extend .is-flex;

    .dropdown-trigger, .dropdown-menu {
      width: 100%;
    }
  }

  .dropdown-item {
    @extend .is-flex;
    @extend .is-flex-direction-row;
    @extend .is-align-items-center;

    * {
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}


// Section styling
.section {
  // Negate section padding on mobile devices
  padding: 0;

  // Include section padding on non-mobile devices
  @include tablet {
    padding: $section-padding-desktop;
  }
}


// Box styling
.box {
  // Negate border radius for mobile devices
  border-radius: 0;

  // Include border radius for non-mobile devices
  @include tablet {
    border-radius: $radius-large;
  }

  // Include border radius for nested boxes
  .box {
    border-radius: $radius-large;
  }

  // Colored box styling
  @each $name, $colors in $colors {
    &.is-#{$name} {
      @extend .has-background-#{$name};
    }
  }
}

// Loading styling
.loading-box {
  @extend .box;
  @extend .is-flex;
  @extend .is-flex-direction-column;
  @extend .is-justify-content-center;
  @extend .is-align-items-center;
  @extend .has-background-white;
  @extend .has-text-grey;

  height: 70vh;
}
